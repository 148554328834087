import React from "react";
import styled from "styled-components";
import Gallery from "../components/Gallery/gallery"
import {graphql, useStaticQuery} from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Tours from "../components/Tours";

const query = graphql`
{
  allFile(filter: {relativeDirectory: {eq: "fleet"}, relativePath: {}}) {
    edges {
      node {
      id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`

const Fleet = () => {
    const data = useStaticQuery(query)
    const {allFile: {edges: images},} = data
    return (
        <Layout>
            <SEO title="Fleet" description={"fleet"} />
            <main>
                <Wrapper>
                    <Gallery images={images} title={"Business Class"}/>
                    <Gallery images={images} title={"Executive Class"}/>
                </Wrapper>
            </main>
        </Layout>
    );
};

const Wrapper = styled.div`
  padding-top: 100px;
  min-height: 700px;
  font-family: sans-serif;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export default Fleet;
